/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "./src/scss/main.scss"
import "./src/font-awesome/css/font-awesome.css"
require("prismjs/themes/prism-solarizedlight.css")
export const onServiceWorkerUpdateReady = () => {
  /**
const answer = window.confirm(
      `This application has been updated. ` +
        `Reload to display the latest version?`
    )
    if (answer === true) {
      window.location.reload()
    }
**/
}
// You can delete this file if you're not using it
